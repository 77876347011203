.notice__overlay {
  z-index: 10;
  background-color: rgba(21, 28, 34, .4);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.notice__overlay .notice__card {
  background: #fff;
  border-radius: 20px;
  width: 100%;
  margin: 35px;
  overflow: hidden;
}

.notice__overlay .notice__card .notice__card__top-loading {
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.notice__overlay .notice__card .notice__card__top--align-top {
  align-items: flex-start;
}

.notice__overlay .notice__card .notice__card__top .card__top__image {
  width: 100%;
  height: 100%;
}

.notice__overlay .notice__card .notice__card__body {
  padding: 24px;
}

.notice__overlay .notice__card .notice__card__body .card__body__btn {
  text-align: center;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: .02em;
  background: #ef7f1a;
  border-radius: 12px;
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
