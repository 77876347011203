html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}

[type="button"] {
  -webkit-appearance: button;
}

[type="reset"] {
  -webkit-appearance: button;
}

[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Inter;
  src: url("~@/assets/fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: auto;
}

@font-face {
  font-family: Inter;
  src: url("~@/assets/fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: auto;
}

@font-face {
  font-family: Inter;
  src: url("~@/assets/fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: auto;
}

@font-face {
  font-family: Inter;
  src: url("~@/assets/fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: auto;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-2px {
  margin: 2px;
}

.mt-2px {
  margin-top: 2px !important;
}

.mr-2px {
  margin-right: 2px !important;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.ml-2px {
  margin-left: 2px !important;
}

.mx-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.my-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.m-4px {
  margin: 4px;
}

.mt-4px {
  margin-top: 4px !important;
}

.mr-4px {
  margin-right: 4px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.ml-4px {
  margin-left: 4px !important;
}

.mx-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-6px {
  margin: 6px;
}

.mt-6px {
  margin-top: 6px !important;
}

.mr-6px {
  margin-right: 6px !important;
}

.mb-6px {
  margin-bottom: 6px !important;
}

.ml-6px {
  margin-left: 6px !important;
}

.mx-6px {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.my-6px {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.m-8px {
  margin: 8px;
}

.mt-8px {
  margin-top: 8px !important;
}

.mr-8px {
  margin-right: 8px !important;
}

.mb-8px {
  margin-bottom: 8px !important;
}

.ml-8px {
  margin-left: 8px !important;
}

.mx-8px {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-10px {
  margin: 10px;
}

.mt-10px {
  margin-top: 10px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.ml-10px {
  margin-left: 10px !important;
}

.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-12px {
  margin: 12px;
}

.mt-12px {
  margin-top: 12px !important;
}

.mr-12px {
  margin-right: 12px !important;
}

.mb-12px {
  margin-bottom: 12px !important;
}

.ml-12px {
  margin-left: 12px !important;
}

.mx-12px {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my-12px {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.m-14px {
  margin: 14px;
}

.mt-14px {
  margin-top: 14px !important;
}

.mr-14px {
  margin-right: 14px !important;
}

.mb-14px {
  margin-bottom: 14px !important;
}

.ml-14px {
  margin-left: 14px !important;
}

.mx-14px {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.my-14px {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.m-15px {
  margin: 15px;
}

.mt-15px {
  margin-top: 15px !important;
}

.mr-15px {
  margin-right: 15px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.ml-15px {
  margin-left: 15px !important;
}

.mx-15px {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-16px {
  margin: 16px;
}

.mt-16px {
  margin-top: 16px !important;
}

.mr-16px {
  margin-right: 16px !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.ml-16px {
  margin-left: 16px !important;
}

.mx-16px {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-17px {
  margin: 17px;
}

.mt-17px {
  margin-top: 17px !important;
}

.mr-17px {
  margin-right: 17px !important;
}

.mb-17px {
  margin-bottom: 17px !important;
}

.ml-17px {
  margin-left: 17px !important;
}

.mx-17px {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.my-17px {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.m-18px {
  margin: 18px;
}

.mt-18px {
  margin-top: 18px !important;
}

.mr-18px {
  margin-right: 18px !important;
}

.mb-18px {
  margin-bottom: 18px !important;
}

.ml-18px {
  margin-left: 18px !important;
}

.mx-18px {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.my-18px {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.m-19px {
  margin: 19px;
}

.mt-19px {
  margin-top: 19px !important;
}

.mr-19px {
  margin-right: 19px !important;
}

.mb-19px {
  margin-bottom: 19px !important;
}

.ml-19px {
  margin-left: 19px !important;
}

.mx-19px {
  margin-left: 19px !important;
  margin-right: 19px !important;
}

.my-19px {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.m-20px {
  margin: 20px;
}

.mt-20px {
  margin-top: 20px !important;
}

.mr-20px {
  margin-right: 20px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.mx-20px {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-24px {
  margin: 24px;
}

.mt-24px {
  margin-top: 24px !important;
}

.mr-24px {
  margin-right: 24px !important;
}

.mb-24px {
  margin-bottom: 24px !important;
}

.ml-24px {
  margin-left: 24px !important;
}

.mx-24px {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.my-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-26px {
  margin: 26px;
}

.mt-26px {
  margin-top: 26px !important;
}

.mr-26px {
  margin-right: 26px !important;
}

.mb-26px {
  margin-bottom: 26px !important;
}

.ml-26px {
  margin-left: 26px !important;
}

.mx-26px {
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.my-26px {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.m-28px {
  margin: 28px;
}

.mt-28px {
  margin-top: 28px !important;
}

.mr-28px {
  margin-right: 28px !important;
}

.mb-28px {
  margin-bottom: 28px !important;
}

.ml-28px {
  margin-left: 28px !important;
}

.mx-28px {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-28px {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.m-32px {
  margin: 32px;
}

.mt-32px {
  margin-top: 32px !important;
}

.mr-32px {
  margin-right: 32px !important;
}

.mb-32px {
  margin-bottom: 32px !important;
}

.ml-32px {
  margin-left: 32px !important;
}

.mx-32px {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.my-32px {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m-64px {
  margin: 64px;
}

.mt-64px {
  margin-top: 64px !important;
}

.mr-64px {
  margin-right: 64px !important;
}

.mb-64px {
  margin-bottom: 64px !important;
}

.ml-64px {
  margin-left: 64px !important;
}

.mx-64px {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.my-64px {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.m-128px {
  margin: 128px;
}

.mt-128px {
  margin-top: 128px !important;
}

.mr-128px {
  margin-right: 128px !important;
}

.mb-128px {
  margin-bottom: 128px !important;
}

.ml-128px {
  margin-left: 128px !important;
}

.mx-128px {
  margin-left: 128px !important;
  margin-right: 128px !important;
}

.my-128px {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}

.m-160px {
  margin: 160px;
}

.mt-160px {
  margin-top: 160px !important;
}

.mr-160px {
  margin-right: 160px !important;
}

.mb-160px {
  margin-bottom: 160px !important;
}

.ml-160px {
  margin-left: 160px !important;
}

.mx-160px {
  margin-left: 160px !important;
  margin-right: 160px !important;
}

.my-160px {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.px-0, .py-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-2px {
  padding: 2px;
}

.pt-2px {
  padding-top: 2px;
}

.pr-2px {
  padding-right: 2px;
}

.pb-2px {
  padding-bottom: 2px;
}

.pl-2px {
  padding-left: 2px;
}

.px-2px, .py-2px {
  padding-left: 2px;
  padding-right: 2px;
}

.p-4px {
  padding: 4px;
}

.pt-4px {
  padding-top: 4px;
}

.pr-4px {
  padding-right: 4px;
}

.pb-4px {
  padding-bottom: 4px;
}

.pl-4px {
  padding-left: 4px;
}

.px-4px, .py-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.p-6px {
  padding: 6px;
}

.pt-6px {
  padding-top: 6px;
}

.pr-6px {
  padding-right: 6px;
}

.pb-6px {
  padding-bottom: 6px;
}

.pl-6px {
  padding-left: 6px;
}

.px-6px, .py-6px {
  padding-left: 6px;
  padding-right: 6px;
}

.p-8px {
  padding: 8px;
}

.pt-8px {
  padding-top: 8px;
}

.pr-8px {
  padding-right: 8px;
}

.pb-8px {
  padding-bottom: 8px;
}

.pl-8px {
  padding-left: 8px;
}

.px-8px, .py-8px {
  padding-left: 8px;
  padding-right: 8px;
}

.p-10px {
  padding: 10px;
}

.pt-10px {
  padding-top: 10px;
}

.pr-10px {
  padding-right: 10px;
}

.pb-10px {
  padding-bottom: 10px;
}

.pl-10px {
  padding-left: 10px;
}

.px-10px, .py-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.p-12px {
  padding: 12px;
}

.pt-12px {
  padding-top: 12px;
}

.pr-12px {
  padding-right: 12px;
}

.pb-12px {
  padding-bottom: 12px;
}

.pl-12px {
  padding-left: 12px;
}

.px-12px, .py-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.p-14px {
  padding: 14px;
}

.pt-14px {
  padding-top: 14px;
}

.pr-14px {
  padding-right: 14px;
}

.pb-14px {
  padding-bottom: 14px;
}

.pl-14px {
  padding-left: 14px;
}

.px-14px, .py-14px {
  padding-left: 14px;
  padding-right: 14px;
}

.p-15px {
  padding: 15px;
}

.pt-15px {
  padding-top: 15px;
}

.pr-15px {
  padding-right: 15px;
}

.pb-15px {
  padding-bottom: 15px;
}

.pl-15px {
  padding-left: 15px;
}

.px-15px, .py-15px {
  padding-left: 15px;
  padding-right: 15px;
}

.p-16px {
  padding: 16px;
}

.pt-16px {
  padding-top: 16px;
}

.pr-16px {
  padding-right: 16px;
}

.pb-16px {
  padding-bottom: 16px;
}

.pl-16px {
  padding-left: 16px;
}

.px-16px, .py-16px {
  padding-left: 16px;
  padding-right: 16px;
}

.p-17px {
  padding: 17px;
}

.pt-17px {
  padding-top: 17px;
}

.pr-17px {
  padding-right: 17px;
}

.pb-17px {
  padding-bottom: 17px;
}

.pl-17px {
  padding-left: 17px;
}

.px-17px, .py-17px {
  padding-left: 17px;
  padding-right: 17px;
}

.p-18px {
  padding: 18px;
}

.pt-18px {
  padding-top: 18px;
}

.pr-18px {
  padding-right: 18px;
}

.pb-18px {
  padding-bottom: 18px;
}

.pl-18px {
  padding-left: 18px;
}

.px-18px, .py-18px {
  padding-left: 18px;
  padding-right: 18px;
}

.p-19px {
  padding: 19px;
}

.pt-19px {
  padding-top: 19px;
}

.pr-19px {
  padding-right: 19px;
}

.pb-19px {
  padding-bottom: 19px;
}

.pl-19px {
  padding-left: 19px;
}

.px-19px, .py-19px {
  padding-left: 19px;
  padding-right: 19px;
}

.p-20px {
  padding: 20px;
}

.pt-20px {
  padding-top: 20px;
}

.pr-20px {
  padding-right: 20px;
}

.pb-20px {
  padding-bottom: 20px;
}

.pl-20px {
  padding-left: 20px;
}

.px-20px, .py-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.p-24px {
  padding: 24px;
}

.pt-24px {
  padding-top: 24px;
}

.pr-24px {
  padding-right: 24px;
}

.pb-24px {
  padding-bottom: 24px;
}

.pl-24px {
  padding-left: 24px;
}

.px-24px, .py-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.p-26px {
  padding: 26px;
}

.pt-26px {
  padding-top: 26px;
}

.pr-26px {
  padding-right: 26px;
}

.pb-26px {
  padding-bottom: 26px;
}

.pl-26px {
  padding-left: 26px;
}

.px-26px, .py-26px {
  padding-left: 26px;
  padding-right: 26px;
}

.p-28px {
  padding: 28px;
}

.pt-28px {
  padding-top: 28px;
}

.pr-28px {
  padding-right: 28px;
}

.pb-28px {
  padding-bottom: 28px;
}

.pl-28px {
  padding-left: 28px;
}

.px-28px, .py-28px {
  padding-left: 28px;
  padding-right: 28px;
}

.p-32px {
  padding: 32px;
}

.pt-32px {
  padding-top: 32px;
}

.pr-32px {
  padding-right: 32px;
}

.pb-32px {
  padding-bottom: 32px;
}

.pl-32px {
  padding-left: 32px;
}

.px-32px, .py-32px {
  padding-left: 32px;
  padding-right: 32px;
}

.p-64px {
  padding: 64px;
}

.pt-64px {
  padding-top: 64px;
}

.pr-64px {
  padding-right: 64px;
}

.pb-64px {
  padding-bottom: 64px;
}

.pl-64px {
  padding-left: 64px;
}

.px-64px, .py-64px {
  padding-left: 64px;
  padding-right: 64px;
}

.p-128px {
  padding: 128px;
}

.pt-128px {
  padding-top: 128px;
}

.pr-128px {
  padding-right: 128px;
}

.pb-128px {
  padding-bottom: 128px;
}

.pl-128px {
  padding-left: 128px;
}

.px-128px, .py-128px {
  padding-left: 128px;
  padding-right: 128px;
}

.p-160px {
  padding: 160px;
}

.pt-160px {
  padding-top: 160px;
}

.pr-160px {
  padding-right: 160px;
}

.pb-160px {
  padding-bottom: 160px;
}

.pl-160px {
  padding-left: 160px;
}

.px-160px, .py-160px {
  padding-left: 160px;
  padding-right: 160px;
}

.text-0 {
  font-size: 0 !important;
}

.text-2px {
  font-size: 2px !important;
}

.text-4px {
  font-size: 4px !important;
}

.text-6px {
  font-size: 6px !important;
}

.text-8px {
  font-size: 8px !important;
}

.text-10px {
  font-size: 10px !important;
}

.text-12px {
  font-size: 12px !important;
}

.text-14px {
  font-size: 14px !important;
}

.text-15px {
  font-size: 15px !important;
}

.text-16px {
  font-size: 16px !important;
}

.text-17px {
  font-size: 17px !important;
}

.text-18px {
  font-size: 18px !important;
}

.text-19px {
  font-size: 19px !important;
}

.text-20px {
  font-size: 20px !important;
}

.text-24px {
  font-size: 24px !important;
}

.text-26px {
  font-size: 26px !important;
}

.text-28px {
  font-size: 28px !important;
}

.text-32px {
  font-size: 32px !important;
}

.text-64px {
  font-size: 64px !important;
}

.text-128px {
  font-size: 128px !important;
}

.text-160px {
  font-size: 160px !important;
}

h1, h2, h3, h4, h5, h6, p {
  color: inherit;
}

h1, .h1 {
  letter-spacing: 0;
  margin: 0;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4.5rem;
}

h2, .h2 {
  letter-spacing: 0;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3rem;
}

h3, .h3 {
  letter-spacing: -.01em;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5rem;
}

h4, .h4 {
  letter-spacing: -.01em;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

h5, .h5 {
  letter-spacing: 0;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

h6, .h6 {
  letter-spacing: 0;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

p {
  letter-spacing: .02em;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}

p.p {
  font-size: 17px;
  line-height: 24px;
}

p.xs, p.xs-medium {
  font-size: 12px;
  line-height: 16px;
}

p.small {
  font-size: 16px;
  line-height: 20px;
}

p.small-medium {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

p.medium {
  font-weight: 500;
}

p.large {
  font-size: 20px;
  line-height: 24px;
}

p.large-medium {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.caption {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.caption-medium {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.label {
  letter-spacing: .08em;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}

.label-small {
  letter-spacing: .06em;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.ant-notification {
  width: 100% !important;
  margin: 0 16px !important;
}

.ant-notification .notification-popup {
  border-radius: 12px;
  padding: 12px 16px;
}

.ant-notification .notification-popup__close {
  background: url("assets/svg/close-white.svg");
  width: 24px;
  height: 24px;
}

.ant-notification .notification-popup__success {
  background-color: #2db77b;
}

.ant-notification .notification-popup__warning {
  background-color: #ef7f1a;
}

.ant-notification .notification-popup__error {
  background-color: #f5463b;
}

.ant-notification .notification-popup__info {
  background-color: #1890ff;
}

.ant-notification .notification-popup__icon-success {
  background: url("assets/svg/notification-success.svg");
  width: 24px;
  height: 24px;
}

.ant-notification .notification-popup__icon-warning {
  background: url("assets/svg/notification-warning.svg");
  width: 24px;
  height: 24px;
}

.ant-notification .notification-popup__icon-error {
  background: url("assets/svg/notification-error.svg");
  width: 24px;
  height: 24px;
}

.ant-notification .notification-popup__icon-info {
  background: url("assets/svg/notification-info.svg");
  width: 24px;
  height: 24px;
}

.ant-notification .notification-popup svg {
  color: #fff;
}

.ant-notification .notification-popup .ant-notification-notice-close {
  top: 11px;
}

.ant-notification .notification-popup .ant-notification-notice-with-icon .ant-notification-notice-message {
  color: #fff;
  margin-bottom: 0;
  margin-left: 35px;
}

.ant-notification .notification-popup .ant-notification-notice-with-icon .ant-notification-notice-description {
  color: #fff;
  margin-left: 35px;
}

.hikvision-stream-container {
  padding: 16px;
  overflow: auto;
}

.hikvision {
  background-color: #fff;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1.5rem 0 1rem;
  display: flex;
}

.hikvision .rotate {
  transform: rotate(90deg);
}

.hikvision .hls-player-container {
  background-color: #4c4b4b;
}

.hikvision .player-container {
  position: relative;
}

.hikvision .player-container .player .play-window {
  border: 1px solid #fff !important;
}

.hikvision .btns {
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: .5rem 0 0;
  display: flex;
}

.hikvision .btns .btn {
  justify-content: center;
  align-items: center;
  display: flex;
}

.hikvision .btns .btn img {
  height: 2rem;
}

.hikvision .close, .hikvision .toggleFS {
  opacity: .5;
  background-color: #fff;
  border-radius: .3rem;
  justify-content: center;
  align-items: center;
  padding: .3rem;
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, .16), 0 4px 6px rgba(0, 0, 0, .45);
}

.hikvision .close .btn_fs, .hikvision .toggleFS .btn_fs {
  justify-content: center;
  align-items: center;
  display: flex;
}

.hikvision .close .btn_fs img, .hikvision .toggleFS .btn_fs img {
  height: 2.5rem;
}

.hikvision .toggleFS {
  left: 1rem;
  right: auto;
}

.hikvision-header {
  color: #101828;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 16px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.hikvision-header-icon {
  display: flex;
}

@media screen and (width <= 768px) {
  .hikvision-stream-container {
    grid-template-columns: repeat(1, 1fr);
    align-content: start;
    gap: 10px;
    display: grid;
  }
}

@media screen and (width >= 768px) and (width <= 1100px) {
  .hikvision-stream-container {
    grid-template-columns: repeat(2, 1fr);
    align-content: start;
    gap: 10px;
    display: grid;
  }
}

@media screen and (width >= 1100px) {
  .hikvision-stream-container {
    grid-template-columns: repeat(3, 1fr);
    align-content: start;
    gap: 10px;
    display: grid;
  }
}

.favorites {
  overflow-y: auto;
}

.favorites .card {
  border: 1px solid #ccc;
  border-radius: 8px;
  flex: none;
  width: 200px;
  margin-right: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

.favorites .card-header {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  border-radius: 8px 8px 0 0;
  padding: 12px;
}

.favorites .card-body {
  padding: 16px;
}

.image {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.dash__error {
  justify-content: center;
  gap: 2rem;
  display: grid;
}

.dash__error__btn {
  color: #fff !important;
}

html {
  box-sizing: border-box;
  color: #151c22;
  height: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  height: 100%;
  min-height: 100%;
  font-family: Inter, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

svg:focus {
  outline: none;
}

a {
  color: #151c22;
  text-decoration: none;
}

.FORMS__error-msg {
  color: #f5463b;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.white-bg {
  background-color: #fff !important;
}

.grey-bg {
  background-color: #f9f9f9 !important;
}

.skeleton-header .v-skeleton-loader__text {
  margin: 0 !important;
}

.skeleton-category .v-skeleton-loader__list-item {
  height: 60px !important;
}

.skeleton-list-of-products-container {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
  display: flex;
}

.skeleton-item {
  margin-bottom: 16px;
  width: 45% !important;
}

.skeleton-orders-container {
  padding: 16px;
}

.skeleton-orders-heading {
  justify-content: space-between;
  margin-bottom: 16px;
  display: flex;
}

.skeleton-orders-heading > .v-skeleton-loader__text {
  flex: none;
  margin: 0 !important;
}

.skeleton-orders-heading > .v-skeleton-loader__text:first-child {
  width: 30%;
}

.skeleton-orders-heading > .v-skeleton-loader__text:last-child {
  width: 15%;
}

.skeleton-orders-images {
  margin-bottom: 16px;
  display: flex;
}

.skeleton-orders-images > .v-skeleton-loader__avatar {
  width: 60px;
  height: 60px;
  margin-right: 8px;
}

.skeleton-orders-footer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.skeleton-orders-footer > .v-skeleton-loader__heading {
  width: 40%;
}

.skeleton-orders-footer > .v-skeleton-loader__text {
  flex: none;
  width: 35%;
  margin: 0 !important;
}

.skeleton-text {
  width: 150px;
  padding: 16px 0 0 16px;
}

.skeleton-list-of-products-lines > div {
  padding: 0 !important;
}

.skeleton-list-of-products-heading > div {
  border-radius: 4px !important;
  width: 100% !important;
}

.header-left-icon {
  top: 13px;
  left: 16px;
  position: absolute !important;
}

.header-right-icon {
  top: 16px;
  right: 16px;
  position: absolute !important;
}

.market-header-container {
  background-color: #f9f9f9;
  padding-bottom: 8px;
}

.market-header-container.product-header {
  padding-bottom: 0;
}

.market-fixed-header {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.market-header-card {
  background-color: #fff;
  border-radius: 0 0 16px 16px;
}

.market-header-title {
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  padding: 10px 16px;
  display: flex;
}

.market-header-main {
  letter-spacing: .02em;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.market-header-category {
  letter-spacing: .02em;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  max-height: 44px;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  display: -webkit-box;
  overflow: hidden;
}

.product-title-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: .02em;
  color: #151c22;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
}

.cart-limit-container {
  background: #90bc34;
  border-radius: 10px;
  flex-shrink: 0;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.cart-limit-amount {
  letter-spacing: .02em;
  color: #fff;
  padding-left: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.market-search-container {
  align-items: center;
  padding: 0 16px 16px;
  display: flex;
}

.market-search-input {
  color: #8e8e93;
  background: rgba(146, 149, 152, .12) url("assets/marketplace/magnify.svg") 8px 10px no-repeat;
  border-radius: 6px;
  width: 100%;
  height: 36px;
  padding-left: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.market-search-actions {
  align-items: center;
  display: flex;
  position: absolute;
  top: 10px;
  right: 16px;
}

.market-search-actions.list-of-products {
  right: 90px;
}

.market-search-actions.list-of-products.is-merch {
  right: 50px;
}

.market-search-btn {
  margin-right: 12px;
  line-height: 16px;
}

.market-cancel-search-btn {
  height: 16px;
  margin-right: 12px;
}

.search-result-container {
  z-index: 5;
  background: #fff;
  height: calc(100vh - 112px);
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.search-product {
  padding: 16px;
}

.market-sort-filter-container {
  margin-left: 6px;
  display: flex;
}

.market-header-with-back-btn {
  align-items: center;
  max-width: 60%;
  display: flex;
}

.go-to-approving-btn {
  position: absolute;
  top: 10px;
  right: 16px;
}

.support-text {
  letter-spacing: .02em;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.rating-container .v-rating .v-icon:after {
  display: none;
}

.layout__body.no-padding {
  padding: 0;
}

.prevent-scrolling {
  overflow: hidden;
}

.market-container {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 72px;
}

.merch-categories-body {
  background: #f9f9f9;
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.market-category-item {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.market-category-left {
  align-items: center;
  display: flex;
}

.market-category-img-static {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.market-category-text {
  letter-spacing: .02em;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.market-category-right {
  align-items: center;
  display: flex;
}

.market-category-products-count {
  text-align: center;
  background-color: rgba(146, 149, 152, .12);
  border-radius: 16px;
  width: 44px;
  margin: 0 8px;
  padding: 4px 8px;
}

.market-divider {
  box-sizing: border-box;
  border: 1px solid #f9f9f9;
}

.market-divider-2 {
  box-sizing: border-box;
  border: 4px solid #f9f9f9;
}

.sorting-panel-bg {
  z-index: 5;
  visibility: hidden;
  background: rgba(255, 255, 255, 0);
  transition: all .3s;
  position: fixed;
  inset: 0;
}

.sorting-panel-bg.active {
  visibility: visible;
  background: rgba(0, 0, 0, .5);
}

.sorting-panel {
  z-index: 5;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 16px;
  transition: transform .3s;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
}

.sorting-panel.active {
  transform: translateY(0);
}

.sorting-list {
  flex-direction: column;
  display: flex;
}

.sorting-list.with-btn {
  margin-bottom: 16px;
}

.sorting-option {
  letter-spacing: .02em;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.selected-sorting-option {
  font-weight: 500;
}

.confirm-sorting-btn {
  background: #ef7f1a;
  border-radius: 16px;
  width: 100%;
  height: 56px;
  padding: 16px;
}

.confirm-sorting-text {
  letter-spacing: .02em;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.market-filter-container {
  z-index: 5;
  background-color: #f9f9f9;
  transition: transform .3s;
  position: fixed;
  inset: 0;
  transform: translateY(100%);
}

.market-filter-container.active {
  transform: translateY(0);
}

.filter-list {
  background-color: #fff;
  border-radius: 16px;
  max-height: calc(100% - 148px);
  overflow-y: scroll;
}

.filter-option {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.filter-option-text {
  flex-direction: column;
  display: flex;
}

.filter-option-title {
  letter-spacing: .02em;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.filter-option-subtitle {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.filter-option-btn-wrapper {
  margin-top: 8px;
}

.filter-option-btn {
  letter-spacing: .02em;
  border: 1px solid #dde1e5;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.filter-option-btn.active {
  border: 1px solid #ef7f1a;
}

.filter-option-input-wrapper {
  justify-content: space-between;
  margin: 8px 0;
  display: flex;
}

.filter-option-input-label {
  letter-spacing: .02em;
  color: #8e8e93;
  padding: 13px 16px 11px;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
}

.filter-option-input {
  letter-spacing: .02em;
  border: 1px solid #dde1e5;
  border-radius: 8px;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 20px;
}

.row-flex {
  flex-wrap: wrap;
  flex: auto;
  display: flex;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex-100 {
  flex: 0 0 100%;
  max-width: 100%;
}

.flex-50 {
  flex: 0 0 50%;
  max-width: 50%;
}

.product-list {
  flex-direction: column;
  padding: 16px;
  display: flex;
}

.product-list-amount {
  color: #8e8e93;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
}

.category-slider-container {
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 8px 16px 16px;
  display: flex;
  overflow-x: auto;
}

.category-slider-container::-webkit-scrollbar {
  display: none;
}

.category-slider {
  letter-spacing: .02em;
  color: #fff;
  background: #ef7f1a;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-right: 8px;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.product-list-card-container {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.product-list-card {
  width: 45%;
  margin-bottom: 16px;
}

.product-list-card-wishlist {
  width: 24px;
  position: relative;
  top: 20px;
  left: calc(100% - 20px);
}

.product-list-image {
  justify-content: center;
  align-items: center;
  height: 130px;
  margin-bottom: 12px;
  display: flex;
}

.product-list-availability {
  letter-spacing: .02em;
  color: #8e8e93;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.product-list-availability.not-available {
  color: #ef7f1a;
}

.product-list-title {
  letter-spacing: .02em;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
}

.product-list-price {
  letter-spacing: .02em;
  color: #151c22;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.add-to-cart-from-list-btn {
  letter-spacing: .02em;
  border: 1px solid #dde1e5;
  border-radius: 8px;
  width: 100%;
  height: 36px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.in-cart-actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.remove-from-cart-btn, .add-more-to-cart-btn {
  border: 1px solid #dde1e5;
  border-radius: 8px;
  flex: 1;
  width: 36px;
  height: 36px;
}

.remove-from-cart-btn:disabled, .add-more-to-cart-btn:disabled {
  color: #dde1e5;
}

.in-cart-amount {
  letter-spacing: .02em;
  padding: 10px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.product-info-card {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 16px;
}

.product-info-availability {
  letter-spacing: .02em;
  color: #8e8e93;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.product-info-availability.not-available {
  color: #ef7f1a;
}

.product-info-subtitle {
  letter-spacing: .02em;
  color: #8e8e93;
  margin: 16px 0 4px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.product-info-title {
  letter-spacing: .02em;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.product-info-bottom {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.product-info-share {
  cursor: pointer;
}

.product-info-price {
  letter-spacing: .02em;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.review-count {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.product-info-description {
  max-height: 48px;
  overflow: hidden;
}

.product-info-description.expanded {
  max-height: unset;
  height: auto;
  overflow: auto;
}

.product-info-description, .product-info-description * {
  letter-spacing: .02em !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.product-expand-description {
  letter-spacing: .02em;
  color: #8e8e93;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.product-info-attributes {
  letter-spacing: .02em;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.product-actions-margin {
  margin-bottom: 160px;
}

.product-actions-wrapper {
  background: #fff;
  position: fixed;
  bottom: 64px;
  left: 0;
  right: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}

.product-actions {
  justify-content: space-between;
  padding: 16px;
  display: flex;
}

.buy-now-btn, .add-to-cart-btn {
  letter-spacing: .02em;
  color: #fff;
  border-radius: 16px;
  width: calc(50% - 4px);
  height: 56px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.buy-now-btn {
  background-color: #90bc34;
}

.add-to-cart-btn {
  background-color: #ef7f1a;
}

.buy-now-btn:disabled, .add-to-cart-btn:disabled {
  background: #8e8e93;
}

.cart-full-error {
  color: #f5463b;
  text-align: center;
  font-size: 14px;
}

.adding-to-cart-bg {
  z-index: 5;
  visibility: hidden;
  background: rgba(255, 255, 255, 0);
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.adding-to-cart-bg.active {
  visibility: visible;
  background: rgba(0, 0, 0, .5);
}

.adding-to-cart-panel {
  z-index: 5;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 32px 16px;
  transition: transform .3s;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
}

.adding-to-cart-panel.active {
  transform: translateY(0);
}

.adding-to-cart-info {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.close-panel-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.adding-to-cart-image {
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 90px;
  display: flex;
}

.reviews-container {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 8px;
}

.review-wrapper {
  padding: 16px;
}

.review-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.review-header-title {
  letter-spacing: .02em;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.review-header-date {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.review-body {
  letter-spacing: .02em;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.review-images-wrapper {
  display: flex;
}

.review-images-container {
  margin-right: 8px;
  display: flex;
}

.review-image {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
  width: 56px;
  height: 56px;
}

.more-reviews {
  letter-spacing: .02em;
  color: #3376f6;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.more-reviews .blue-chevron {
  filter: brightness(0) saturate() invert(34%) sepia(38%) saturate(3414%) hue-rotate(209deg) brightness(104%) contrast(93%);
  padding-left: 6px;
}

.product-photo-carousel {
  background: #fff;
  border-radius: 0 0 16px 16px;
  margin-bottom: 8px;
}

.product-photo-carousel .v-carousel__controls .v-btn--icon {
  width: 8px;
  height: 8px;
}

.product-photo-carousel .v-carousel__controls .v-btn--icon:not(.v-btn--active) {
  border: 1px solid #d9d9d9;
}

.product-photo-carousel .v-carousel__controls .v-btn--icon.v-btn--active {
  color: #d9d9d9;
}

.product-photo-carousel .v-carousel__controls .v-btn--icon.v-btn--active:before {
  opacity: 0;
}

.product-photo-carousel .v-carousel__controls .v-btn--icon .v-icon {
  width: 8px;
  height: 8px;
  font-size: 8px !important;
}

.cart-header-title {
  letter-spacing: .02em;
  color: #151c22;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.cart-container {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.empty-cart {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 400px;
  margin-top: 50px;
  display: flex;
  padding: 0 48px !important;
}

.empty-cart-icon {
  margin-bottom: 16px;
}

.empty-cart-title {
  text-align: center;
  letter-spacing: .02em;
  color: #151c22;
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.empty-cart-text {
  text-align: center;
  letter-spacing: .02em;
  color: #8e8e93;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.go-to-market-btn {
  letter-spacing: .02em;
  text-transform: none;
  border-radius: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff !important;
  font-size: 20px !important;
}

.cart-info-card {
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 16px !important;
  display: flex !important;
}

.cart-info-text {
  letter-spacing: .02em;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.cart-info-text.negative-limit {
  color: #f5463b;
}

.cart-price {
  justify-content: space-between !important;
}

.cart-item-info {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 16px;
  display: flex;
}

.cart-item-price {
  letter-spacing: .02em;
  color: #151c22;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.cart-item-price-one {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.73px;
}

.cart-item-title {
  letter-spacing: .02em;
  color: #101828;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
}

.cart-item-actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cart-item-quantity-btn {
  border: 1px solid #dde1e5;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  color: #8e8e93 !important;
}

.cart-item-quantity {
  letter-spacing: .02em;
  text-align: center;
  color: #8e8e93;
  align-items: center;
  min-width: 48px;
  padding: 0 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.cart-item-quantity > input {
  text-align: center;
  border: 1px solid #dde1e5;
  border-radius: 8px;
  width: 56px;
  height: 36px;
}

.cart-item-remove {
  line-height: 20px;
  color: #8e8e93 !important;
  height: 20px !important;
  padding: 0 !important;
}

.cart-order-card {
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 16px !important;
}

.cart-order-text {
  letter-spacing: .02em;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.cart-order-select {
  letter-spacing: .02em;
  color: #ef7f1a;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.cart-select-header {
  background-color: #fff;
  border-radius: 16px;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
}

.cart-select-title {
  text-align: center;
  letter-spacing: .02em;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.cart-select-items {
  background-color: #f9f9f9;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  display: flex;
}

.cart-select-item {
  letter-spacing: .02em;
  background-color: #fff;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.cart-confirm-order-margin {
  margin-bottom: 160px !important;
}

.cart-confirm-order {
  bottom: 64px;
  left: 0;
  right: 0;
  position: fixed !important;
}

.cart-confirm-order-btn {
  letter-spacing: .02em;
  text-transform: none;
  border-radius: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff !important;
  height: 56px !important;
  padding: 16px !important;
  font-size: 20px !important;
}

.order-card {
  border-radius: 16px;
  flex-direction: column;
  padding: 16px 0 0;
  display: flex;
}

.order-title {
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.order-status {
  letter-spacing: .02em;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.order-number {
  color: #8e8e93;
  letter-spacing: .02em;
  margin: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.order-comments {
  letter-spacing: .02em;
  color: #8e8e93;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.order-photos {
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 16px;
  display: flex;
  overflow-x: auto;
}

.order-photos::-webkit-scrollbar {
  display: none;
}

.order-photo-container {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-right: 8px;
  display: flex;
}

.order-subtitle {
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 16px;
  display: flex;
}

.order-price {
  letter-spacing: .02em;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.order-product-count {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.order-info {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 16px;
}

.order-info-text {
  letter-spacing: .02em;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.order-price-info {
  flex-direction: column;
  margin-bottom: 16px;
  display: flex;
}

.order-price-title {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.order-info-price {
  letter-spacing: .02em;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

.order-status-info {
  margin-right: 32px;
}

.order-status-info, .order-product-amount-info {
  flex-direction: column;
  display: flex;
}

.order-status-title, .order-product-amount-title {
  letter-spacing: .02em;
  color: #8e8e93;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.order-product-amount {
  letter-spacing: .02em;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.order-products-container {
  background-color: #fff;
  border-radius: 16px;
}

.order-unchecked {
  border: 1px solid #c7c7c7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 16px;
}

.order-products {
  flex-direction: column;
  width: 100%;
  padding: 16px;
  display: flex;
}

.order-product-status-and-delivery {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.order-product-status {
  letter-spacing: .02em;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.order-product-delivery {
  align-items: center;
  display: flex;
}

.order-product-delivery > span {
  margin-left: 6px;
}

.order-product-vendor-code, .order-product-quantity {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.order-product-title {
  letter-spacing: .02em;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.order-product-price {
  letter-spacing: .02em;
  margin-right: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.orange-status {
  color: #ef7f1a;
}

.red-status {
  color: #f5463b;
}

.green-status {
  color: #90bc34;
}

.support-card {
  padding: 16px;
}

.support-card-title {
  letter-spacing: .02em;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.support-item {
  margin-top: 8px;
}

.support-item-title {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.support-item-subtitle {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.support-item-number, .support-item-link {
  letter-spacing: .02em;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
}

.support-item-link {
  color: #ef7f1a;
  text-decoration: underline;
}

.favourite-toggler-row {
  align-items: center;
  display: flex;
}

.favourite-toggler-row--spaced {
  padding: 12px 16px;
}

.favourite-toggler-row__title {
  flex-grow: 1;
  margin: 0 12px 0 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
}

.favourite-toggler-row__title__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.favourite-toggler-row__toggler {
  background: #eef0f2;
  border-radius: 12px;
  width: 36px;
  padding: 2px;
}

.favourite-toggler-row__toggler__circle-container {
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  transition: width .1s ease-in;
}

.favourite-toggler-row__toggler__circle {
  background: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-shadow: 0 1px 3px rgba(16, 24, 40, .1), 0 1px 2px rgba(16, 24, 40, .06);
}

.favourite-toggler-row__toggler--active {
  background: #ef7f1a;
}

.favourite-toggler-row__toggler--active .favourite-toggler-row__toggler__circle-container {
  width: 16px;
}

.favourite-toggler-row__toggler--disabled {
  background: #eef0f2;
}

#shareAPIPolyfill-container, #shareAPIPolyfill-container > .shareAPIPolyfill-header {
  border-radius: 16px 16px 0 0;
}

#shareAPIPolyfill-container > .shareAPIPolyfill-header > .shareAPIPolyfill-header-title {
  letter-spacing: 0;
  border-radius: 16px 16px 0 0;
  font-size: 18px;
  line-height: 24px;
  color: #000 !important;
  background-color: #ef7f1a !important;
  font-weight: 500 !important;
}

#shareAPIPolyfill-container > .shareAPIPolyfill-header > .shareAPIPolyfill-icons-container > button > svg > path:first-child {
  fill: #ef7f1a !important;
}

#shareAPIPolyfill-container > .shareAPIPolyfill-body {
  padding-bottom: 8px;
}

#shareAPIPolyfill-container > .shareAPIPolyfill-body > button {
  letter-spacing: 0;
  border-radius: 8px;
  margin: 0 auto;
  font-size: 16px;
  opacity: 1 !important;
  color: #000 !important;
  background-color: #ef7f1a !important;
  width: 90% !important;
  padding: 12px !important;
  font-weight: 500 !important;
}

.main-sub-menu {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.main-sub-menu--rounded {
  border-radius: 18px;
}

.main-sub-menu--rounded-top {
  border-radius: 18px 18px 0 0;
}

.main-sub-menu__item {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.main-sub-menu__item:not(:last-child) {
  border-bottom: 1px solid #f9f9f9;
}

.main-sub-menu__item .item__icon {
  flex: 0 0 20px;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin-right: 16px;
  display: flex;
}

.main-sub-menu__item .item__icon img {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 100%;
  height: 100%;
}

.main-sub-menu__item .item__content {
  flex: auto;
  margin: 0 16px 0 0;
}

.main-sub-menu__item .item__icon-right {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.main-sub-menu__item .item__icon-right img {
  max-width: 100%;
}

.main-sub-menu__link:not(:last-child) {
  border-bottom: 1px solid #f9f9f9;
}

.application {
  border: 1px solid #fafafa;
  flex-direction: column;
  gap: 16px;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 14px 16px;
  display: flex;
}

.application-header {
  gap: 16px;
  display: flex;
}

.application-body {
  flex-direction: column;
  gap: 8px;
  width: 100%;
  display: flex;
}

.application-body-item {
  padding: 0 40px;
}

.application-body-item-title {
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.application-body-item-text {
  color: #101828;
  letter-spacing: 0;
  background: rgba(0, 0, 0, .25);
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.application-body-item-text.url-input {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.application-icon {
  background: #f6f6f5;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  display: flex;
}

.application-text {
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.application-text-title {
  color: #151c22;
  font-size: 16px;
  font-weight: 500;
}

.application-text-executor {
  color: #8e8e93;
}

.application-text-executor > span > a {
  color: #3791f2;
}

.application-text-status > p {
  letter-spacing: .02em;
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.application-button {
  color: #3791f2;
  border-bottom: 1px solid #fafafa;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 32px);
  margin-bottom: 4px;
  margin-left: -16px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.application-default-button {
  color: #fff;
  letter-spacing: .02em;
  text-align: center;
  background: #ef7f1a;
  border-radius: 6px;
  width: 100%;
  padding: 6px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
}

.filter-window {
  z-index: 10;
  background: #f9f9f9;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
}

.filter-window-header {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 16px;
  display: flex;
}

.filter-window-title {
  letter-spacing: .02em;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.filter-window-subtitle {
  color: #8e8e93;
  letter-spacing: .02em;
  margin-top: 4px;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.filter-window-option {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-top: 1px;
  padding: 0 16px;
  display: flex;
}

.filter-window-option-text {
  letter-spacing: .02em;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.filter-window-submit {
  background: #fff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 16px;
  position: fixed;
  bottom: 0;
}

.filter-window-submit-button {
  color: #fff;
  letter-spacing: .02em;
  background-color: #ef7f1a;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.filter-window-submit-button:disabled {
  background: #8e8e93;
}

.filter-window-datepicker > div > .ant-calendar-picker {
  opacity: 0;
}

.cart-add-remove-btn {
  background-color: #fdf2e8;
  border: 0;
  color: #ef7f1a !important;
}

.cart-add-remove-btn:disabled {
  background: none;
  border: 1px solid #dde1e5;
  color: #8e8e93 !important;
}

.market-alert {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 8px;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 16px;
  display: flex;
}

.market-alert-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.market-alert-message-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.market-alert.error {
  background-color: #fff1f0;
  border-color: #ffa39e;
}

.pop-enter-active, .pop-leave-active {
  transition: transform .4s cubic-bezier(.5, 0, .5, 1), opacity .4s linear;
}

.pop-enter, .pop-leave-to {
  opacity: 0;
}
