/* purgecss start ignore */

.layout{
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between
}

.layout .layout__header{
  padding:6px 16px;
  background-color:#fff;
  position:fixed;
  left:0;
  top:0;
  right:0;
  z-index:2
}

.layout .layout__header--gray{
  background-color:#f9f9f9
}

.layout .layout__body{
  background-color:#f9f9f9;
  padding-top:8px;
  flex-basis:auto;
  flex-grow:1;
  min-width:0
}

@supports(padding: max(0px)){
  .layout .layout__body{
    padding-bottom:calc(16px + env(safe-area-inset-bottom, 0px))
  }
}

.layout .layout__body--main{
  background-color:#f3f3f3;
  padding-top:0
}

.layout .layout__body--white{
  background-color:#fff
}

.layout .layout__body--notifications{
  padding-top:0
}

.layout .layout__body--way{
  padding-left:16px;
  padding-right:16px;
  background-color:#fff
}

.layout .layout__body--events{
  padding-left:16px;
  padding-right:16px;
  background-color:#fff
}

.layout .layout__body--error-placeholder{
  display:flex;
  flex-direction:column
}

.layout .layout__body--centered{
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding-bottom:128px
}

/* purgecss end ignore */