/* purgecss start ignore */

.layout .v-speed-dial{
  position:fixed
}

.layout .v-speed-dial .v-btn{
  border:1px solid #dde1e5;
  box-shadow:0px 1px 10px rgba(102,122,139,.12)
}

.layout .v-speed-dial .v-btn:before{
  opacity:0 !important
}

.floating-nav{
  bottom:128px;
  z-index:999
}

.floating-nav .floating-nav__item{
  position:relative
}

.floating-nav .floating-nav__item svg:focus{
  outline:none
}

/* purgecss end ignore */