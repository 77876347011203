.loader-screen {
  color: #fff;
  background: linear-gradient(213.26deg, #9babba 11.27%, #7b8a98 102.62%), linear-gradient(#d3d4d7 0%, rgba(211, 212, 215, 0) 100%), #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.loader-screen > .loader-screen__logo-container {
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: 32px 0 0;
  display: flex;
}

.loader-screen > .loader-screen__logo-container .logo {
  fill: currentColor;
}

.loader-screen > .loader-screen__logo-container .logo:not(.animate-pulse) {
  color: #fff;
}

.loader-screen > .loader-screen__logo-container .animate-pulse {
  animation: .7s infinite pulse-white;
}

.loader-screen > .loader-screen__logo-container svg.logo--dark {
  width: 100%;
  height: auto;
}

.loader-screen > .loader-screen__logo-container svg.logo--dark .dark-pulse-target {
  opacity: 1;
}

.loader-screen > .loader-screen__logo-container svg.logo--dark .dark-pulse-2-target {
  opacity: 1;
  color: #c5c7c8;
}

.loader-screen > .loader-screen__error {
  text-align: center;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  margin: 0 16px;
  display: flex;
}

.loader-screen > .loader-screen__bottom {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.loader-screen.loader-screen--light {
  color: #151c22;
  background: #fff;
}

.loader-screen.loader-screen--light > .loader-screen__logo-container .logo {
  color: #151c22;
  fill: currentColor;
}

.loader-screen.loader-screen--light > .loader-screen__logo-container .logo:not(.animate-pulse) {
  color: #151c22;
}

.loader-screen.loader-screen--light > .loader-screen__logo-container .animate-pulse {
  animation: .7s infinite pulse-dark;
}

.loader-screen.loader-screen--dark {
  background: #282c32;
}

.loader-screen.loader-screen--dark > .loader-screen__logo-container .logo {
  fill: currentColor;
}

.loader-screen.loader-screen--dark > .loader-screen__logo-container .logo:not(.animate-pulse), .loader-screen.loader-screen--dark > .loader-screen__logo-container .loader-screen__error {
  color: #fff;
}

@keyframes pulse-white {
  0% {
    color: #fafafa;
  }

  50% {
    color: #ddd;
  }

  100% {
    color: #fafafa;
  }
}

@keyframes pulse-dark {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse-dark-2 {
  0% {
    color: #c5c7c8;
  }

  50% {
    color: #999;
  }

  100% {
    color: #c5c7c8;
  }
}

.reload-icon--rotating {
  animation: 2s linear infinite loader-rotating;
}

@keyframes loader-rotating {
  0% {
    transform: rotate(360deg);
  }

  25% {
    transform: rotate(270deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0);
  }
}
