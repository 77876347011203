/* purgecss start ignore */

.market-loader-bg{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#fff
}

.market-loader{
  margin-top:100px;
  padding:16px;
  display:flex;
  flex-direction:column;
  align-items:center
}

.market-icon-container{
  margin-bottom:20px;
  height:100px
}

.loading-text{
  margin-top:24px;
  margin-bottom:24px;
  font-weight:600;
  font-size:24px;
  line-height:28px;
  letter-spacing:.02em;
  text-align:center
}

.loading-error{
  margin-bottom:24px;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  letter-spacing:.02em;
  text-align:center
}

/* purgecss end ignore */