/* purgecss start ignore */

.loader-screen{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  height:100%;
  background:linear-gradient(213.26deg, #9BABBA 11.27%, #7B8A98 102.62%),linear-gradient(180deg, #D3D4D7 0%, rgba(211, 212, 215, 0) 100%),#fff;
  color:#fff
}

.loader-screen>.loader-screen__logo-container{
  flex:1 0 auto;
  width:33%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:32px 0 0 0
}

.loader-screen>.loader-screen__logo-container .logo{
  fill:currentColor
}

.loader-screen>.loader-screen__logo-container .logo:not(.animate-pulse){
  color:#fff
}

.loader-screen>.loader-screen__logo-container .animate-pulse{
  animation:pulse-white .7s infinite
}

.loader-screen>.loader-screen__logo-container svg.logo--dark{
  width:100%;
  height:auto
}

.loader-screen>.loader-screen__logo-container svg.logo--dark .dark-pulse-target{
  opacity:1
}

.loader-screen>.loader-screen__logo-container svg.logo--dark .dark-pulse-2-target{
  opacity:1;
  color:#c5c7c8
}

.loader-screen>.loader-screen__error{
  flex:0 1 auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
  align-items:center;
  text-align:center;
  margin:0 16px
}

.loader-screen>.loader-screen__bottom{
  flex:1 0 auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
  align-items:center;
  margin-bottom:32px;
  -webkit-user-select:none;
     -moz-user-select:none;
          user-select:none
}

.loader-screen.loader-screen--light{
  background:#fff;
  color:#151c22
}

.loader-screen.loader-screen--light>.loader-screen__logo-container .logo{
  color:#151c22;
  fill:currentColor
}

.loader-screen.loader-screen--light>.loader-screen__logo-container .logo:not(.animate-pulse){
  color:#151c22
}

.loader-screen.loader-screen--light>.loader-screen__logo-container .animate-pulse{
  animation:pulse-dark .7s infinite
}

.loader-screen.loader-screen--dark{
  background:#282c32
}

.loader-screen.loader-screen--dark>.loader-screen__logo-container .logo{
  fill:currentColor
}

.loader-screen.loader-screen--dark>.loader-screen__logo-container .logo:not(.animate-pulse){
  color:#fff
}

.loader-screen.loader-screen--dark>.loader-screen__logo-container .loader-screen__error{
  color:#fff
}

@keyframes pulse-white{
  0%{
    color:#fafafa
  }

  50%{
    color:#ddd
  }

  100%{
    color:#fafafa
  }
}

@keyframes pulse-dark{
  0%{
    opacity:1
  }

  50%{
    opacity:.8
  }

  100%{
    opacity:1
  }
}

@keyframes pulse-dark-2{
  0%{
    color:#c5c7c8
  }

  50%{
    color:#999
  }

  100%{
    color:#c5c7c8
  }
}

.reload-icon--rotating{
  animation:loader-rotating 2s linear infinite
}

@keyframes loader-rotating{
  0%{
    transform:rotate(360deg)
  }

  25%{
    transform:rotate(270deg)
  }

  50%{
    transform:rotate(180deg)
  }

  75%{
    transform:rotate(90deg)
  }

  100%{
    transform:rotate(0deg)
  }
}

/* purgecss end ignore */