.market-loader-bg {
  background-color: #fff;
  position: absolute;
  inset: 0;
}

.market-loader {
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding: 16px;
  display: flex;
}

.market-icon-container {
  height: 100px;
  margin-bottom: 20px;
}

.loading-text {
  letter-spacing: .02em;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

.loading-error {
  letter-spacing: .02em;
  text-align: center;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
