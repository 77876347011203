/* purgecss start ignore */

.notice__overlay{
  position:fixed;
  overflow-y:auto;
  background-color:rgba(21,28,34,.4);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:10;
  display:flex;
  justify-content:center;
  align-items:center
}

.notice__overlay .notice__card{
  background:#fff;
  margin:35px;
  border-radius:20px;
  overflow:hidden;
  width:100%
}

.notice__overlay .notice__card .notice__card__top-loading{
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:24px
}

.notice__overlay .notice__card .notice__card__top--align-top{
  align-items:flex-start
}

.notice__overlay .notice__card .notice__card__top .card__top__image{
  width:100%;
  height:100%
}

.notice__overlay .notice__card .notice__card__body{
  padding:24px
}

.notice__overlay .notice__card .notice__card__body .card__body__btn{
  background:#ef7f1a;
  border-radius:12px;
  text-align:center;
  color:#fff;
  padding:8px;
  -webkit-user-select:none;
     -moz-user-select:none;
          user-select:none;
  font-style:normal;
  font-weight:600;
  font-size:14px;
  line-height:20px;
  letter-spacing:.02em
}

/* purgecss end ignore */